.assess-result-header {
  background: #485258;
  padding: 30px 16px;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .assess-result-header-title {
    font-size: 27px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .assess-result-header-id {
    font-size: 12px;
    text-align: center;
    padding: 3px 10px;
    background: #979797;
    border-radius: 12px;
  }

  .assess-result-header-info {
    font-size: 14px;
    margin-top: 38px;
  }
}

.assess-result-section-title {
  padding: 25px 16px 15px 16px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  text-align: left;
}

.assess-result-section-desc {
  padding: 0 16px 15px 16px;
  font-size: 13px;
  color: #666;
  text-align: left;
  line-height: 1.5;
}

.assess-result-image-list {
  padding: 0 16px;
  overflow-x: auto;
  text-align: left;

  ul {
    overflow-x: auto;
    white-space: nowrap;

    li {
      display: inline-block;
      width: 154px;
      margin-right: 12px;

      .assess-result-image-item {
        font-size: 13px;
        color: #333;

        .assess-result-image-title {
          text-align: left;
          padding: 10px 2px;
        }

        img {
          width: 100%;
          height: 189px;
          border-radius: 5px;
          object-fit: cover;
        }
      }
    }
  }
}

.assess-result-test-wrapper {
  margin: 0px 16px 16px 16px;
  padding: 0px 15px;
  background: #f9f9f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;

  .assess-result-test-title {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding: 12px 0px 18px 0px;
  }

  .assess-result-test-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .assess-result-test-info-item {
      width: calc(100% / 3);

      .assess-result-test-info-item-title {
        font-size: 11px;
        color: #b9b9b9;
      }

      .assess-result-test-info-item-value {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        padding: 6px 0px 20px 0px;
      }
    }
  }

  .assess-result-test-desc {
    font-size: 13px;
    color: #717171;
    padding: 20px 0px;
    border-top: 1px #eee solid;
    line-height: 1.5;
  }
}

.assess-result-sug-wrapper {
  margin: 0px 0px 16px 0px;
  padding: 0px 16px 100px 16px;
  background: #f5f9fa;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;

  .assess-result-sug-header {
    padding: 12px 0px;
    display: flex;
    flex-direction: row;
    color: #333;
    font-size: 14px;

    img {
      display: inline-block;
      width: 44px;
      height: 44px;
    }

    .assess-result-sug-header-info {
      display: flex;
      padding-left: 12px;
      flex-direction: column;
      justify-content: space-between;

      .assess-result-sug-header-name {
        padding-top: 5px;
        color: #333;
        font-size: 13px;
      }

      .assess-result-sug-header-desc {
        font-size: 10px;
        color: #919c9d;
        padding-bottom: 5px;
      }
    }
  }

  .assess-result-sug-text {
    font-size: 13px;
    color: #3a3a3a;
    line-height: 1.4;
  }
}

.assess-result-training-wrapper {
  margin: 0px 16px 16px 16px;
  color: #fff;
  background: #eee;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  text-align: left;

  .assess-result-training-title {
    font-size: 19px;
    font-weight: 500;
    padding-top: 20%;

    margin: 0px 15px;
  }

  .assess-result-training-subtitle {
    font-size: 10px;
    margin: 4px 15px 12px 15px;
  }
}

.assess-result-placehlder {
  height: 10px;
  background: #f7f7f7;
}

.assess-result-article-wrapper {
  text-align: left;
  margin-bottom: 200px;

  .assess-result-article-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin: 30px 16px 10px 16px;
  }

  .assess-result-article-item {
    .assess-result-article-text {
      color: #717171;
      font-size: 12px;
      line-height: 1.5;
      padding: 8px 16px;
    }

    .assess-result-article-media {
      video {
        width: 100%;
        margin: 12px 0px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.assess-img-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.assess-modal-content {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

.assess-modal-close {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  padding: 7.5px 8px 5px 8px;
  border-radius: 16px;

  img {
    width: 14.5px;
    height: 15px;
  }
}

.assess-modal-banner {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .assess-modal-image {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
}

.banner-wrapper {
  position: relative;
  margin: 0px;
  .banner {
    position: relative;
  }
}

.assess-test-table {
  margin: 0px 14px;
  width: calc(100% - 28px);
  border: 1px #ccc solid;
  border-collapse: collapse;

  tr {
    border-bottom: 1px #ccc solid;

    th {
      border-right: 1px #ccc solid;
      padding: 5px 0px;
      background: #e9f7ff;
      color: #3f3f3f;
    }

    td {
      width: 60px;
      border-right: 1px #ccc solid;
      padding: 6px 0px;

      input {
        text-align: center;
      }
    }
  }
}

.ar {
  &-sco {
    display: flex;
    align-items: center;
    margin: 14px;
    color: #121725;

    &-left {
      position: relative;
      img {
        width: 94px;
        height: 112px;
        border-radius: 8px;
        overflow: hidden;
      }

      &-label {
        position: absolute;
        right: 8px;
        bottom: 10px;
        background: #12172566;
        color: #fff;
        font-size: 10px;
        text-align: center;
        padding: 1px 6px;
        border-radius: 8px;
      }
    }

    &-mid {
      margin: 0 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;

      &-info {
        margin-top: 6px;
        display: flex;
        align-items: center;

        &-type {
          font-size: 34px;
          font-weight: 600;
        }

        &-tips {
          display: flex;
          align-items: center;
          color: #657181;
          font-size: 12px;
          border-radius: 4px;
          border: 1px solid #65718152;
          background: #ffffff33;
          margin-left: 8px;
          padding: 2px 8px;
        }
      }
    }

    &-right {
      img {
        width: 50px;
        height: 92px;
        object-fit: contain;
      }
    }
  }

  &-posture {
    border-radius: 8px;
    margin: 0 14px;
    border: 1px solid #eee;

    video {
      width: calc(100% - 28px);
      height: 100%;
    }
  }

  &-bodylist {
    margin: 0 14px 14px 14px;
    color: #121725;
    font-size: 16px;

    &-card {
      margin-bottom: 14px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &-body {
        font-size: 17px;
        font-weight: 600;
        min-width: 90px;
        line-height: 24px;
        margin-top: 16px;
      }

      &-items {
        width: 100%;
      }

      &-item {
        display: flex;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #f3f4f6;

        &-name {
          flex-grow: 1;
          text-align: left;
        }

        &-value {
          text-align: right;
          margin-right: 14px;
        }
      }
    }
  }

  &-eqp {
    margin: 0 14px 14px 14px;
    display: flex;
    align-items: center;
    color: #121725;
    font-size: 14px;

    &-item {
      width: calc(25% - 7px);

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }

      &-name {
        margin-top: 8px;
      }
    }
  }

  &-sco-title {
    padding: 25px 16px 15px 16px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    text-align: left;
    display: flex;
    align-items: center;

    &-result {
      font-size: 12px;
      color: #657181;
      display: flex;
      padding: 3px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #65718152;
      background: #ffffff33;
      margin-left: 10px;
    }
  }

  &-page {
    background: linear-gradient(0deg, #dbf4e700 56.65%, #dbf4e7 100%), #f8f9fa;
    background-repeat: no-repeat;
    margin-top: -14px;
    padding-top: 14px;
  }
}

@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
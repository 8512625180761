.text-input-wrapper {
  width: 100%;
  height: 60px;
}

.text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border: none;

  padding: 0px;
  text-align: right;
  font-size: 15px;
  color: black;
  flex-grow: 1;
}

input::-webkit-input-placeholder {
  color: #CCCCCC;
  font-size: 15px;
}

input::-moz-placeholder {
  color: #CCCCCC;
  font-size: 15px;
}

.text-label {
  width: 100%;
  height: 60px;
  color: #333333;
  font-size: 15px;
  touch-action: manipulation;
}

.text-info {
  border-bottom: 1px #EEEEEE solid;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0px 14px 0px 14px;
  padding: 20px 0px;

  .text-title {
    flex-shrink: 0;
    text-align: left;
    color: #333333;
    font-size: 15px;
  }

  .text-value {
    flex-grow: 1;
    text-align: right;
    overflow: hidden;
  }

  .text-placeholder {
    flex-grow: 0;
    text-align: right;
    color: #CCCCCC;
    font-size: 15px;
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
* {
  padding: 0;
  margin: 0;
}
html,
body {
  background-color: #fff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
.body-container {
  max-width: 1200px;
  margin: 0 auto;
}
input {
  outline: none;
}
#loading {
  background-image: url("common/images/loading.svg");
  background-size: 28px 28px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
  animation: loading 2.5s linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
#toast {
  position: fixed;
  z-index: 10001;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.75);
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
}
@media screen and (max-width: 414px) {
  .body-container {
    width: 100%;
  }
}

:root:root {
  --adm-color-primary: #00b578;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;

  --adm-color-white: #ffffff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;
}

.banner-wrap{
  position: relative;
  .banner{
    position: relative;
  }
  z-index: 10;
}

.item-wrapper {
  margin: 0px 20px 0px 20px;
  img {
    width: 100%;
  }
  .mobile-input {
    background-color: #F0EFEF;
    border-radius: 7px;
    text-align: center;
    height: 50px;
    input {
      text-align: center;
    }
  }

  .error-tips {
    text-align: left;
    font-size: 10px;
    color: #EC3326;
    margin-top: 8px;
  }
}

.img-seperator {
  background-color: #F2F2F2;
  height: 12px;
}

.intro-img {
  width: 100%;
}

.user-info {
  margin: -22px 20px 0px 20px;
  padding: 7px 12px 7px 12px;
  background-color: #F8EAEA;
  border-radius: 22px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
  position: relative;
  z-index: 11;
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: inline-block;
  }
  .info-wrapper {
    display: inline-block;
    text-align: left;
    padding: 0px 10px 0px 10px;
  }
  .nickname {
    font-size: 12px;
    font-weight: 600;
    color: #6151AA;
    margin-right: 4px;
  }
  .gift-info {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-right: 4px;
  }
  .beautiful {
    font-size: 12px;
    font-weight: 600;
    color: #EE8EB6;
  }
}

.scroll-mobile {
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #A3A3A3;
  padding: 10px 10px 10px 10px;
  .nickname {
    display: inline;
    color: #B5B2D1;
    margin-right: 4px;
  }
  .gift-info {
    display: inline;
    color: #BEBEBE;
    margin-right: 4px;
  }
  .coupon-info {
    display: inline;
    color: #E6ACD1;
  }
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px 4px 6px;
  margin-right: 10px;
  font-size: 10px;
  border-radius: 12px;
  background-color: rgba(2, 2, 2, 0.8);
  color: #fff;
}

.bot-download {
  background-color: rgba(0, 0, 0, 0.75);
  z-index:999;
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
  height: 50px;
  text-align: center;

  .download-content {
    margin: 7px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download-btn {
      text-align: center;
      border: 2px solid #fff;
      border-radius: 5px;
      padding: 8px;
      color: #fff;
      font-size: 13px;
    }

    .download-text {
      color: #fff;
      font-size: 11px;
      line-height: 17px;
      text-align: left;
      flex-grow: 1;
      margin: 0px 12px;
    }

    img {
      width: 34px;
      height: 34px;
    }
  }
}

.download-wrapper {
  background-color: #fff;
  text-align: center;

  img {
    width: 99px;
  }
  .main-title {
    color: #6E5454;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding: 0px;
    margin: 0px;
  }
  .sub-title {
    color: #6E5454;
    font-size: 12px;
    margin: 20px 0px 18px 0px;
  }
  .close-wrapper {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #fff;
    padding: 15px;
    img {
      width: 15px;
    }
  }
}

@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
.upload-wrapper {
  width: 100%;
  height: 100%;
  align-self: stretch;

  .upload-label {
    width: 100%;
    height: 100%;
    color: #333333;
    font-size: 12px;
    touch-action: manipulation;

    .upload-button {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      background-color: #FAFAFA;
      border: 1px #EEE solid;
  
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
      }
    }

    .upload-media-preview {
      position: relative;
      width: 100%;
      height: 100%;

      .upload-preview-image {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;

        video {
          width: 100%;
          object-fit: contain;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .upload-preview-image-btn {
        position: absolute;
        z-index: 3;
        right: 5px;
        top: 5px;
      }
      
      .upload-preview-status {
        position: absolute;
        z-index: 2;
        bottom: 0px;
        left: 0px;
        right: 0px;

        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 5px;
      }
    }
  }

  .upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}


@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
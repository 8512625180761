.stp {
  background: url("https://syh-img.shareyourhealth.cn/egghealthma/ic_qqcz_instruction_bg.png");
  background-color: #f8f9fa;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #121725;

  &-header {
    margin: 0 20px 20px 20px;
    padding-top: 284px;

    &-main {
      text-align: center;
      font-size: 12px;
      color: #495b74;
      opacity: 0.95;
      padding: 100px 14px 34px 14px;

      &-title {
        background-image: -webkit-linear-gradient(
          0deg,
          #1b947f 2.12%,
          #0a6384 102.3%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-weight: 600;
      }

      &-subTitle {
        background-image: -webkit-linear-gradient(
          0deg,
          #18c76a 2.12%,
          #0a6384 102.3%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 25px;
        font-weight: 600;
      }

      &-sub {
        font-size: 12px;
        text-align: left;
        margin-top: 48px;
        padding: 6px 12px;
        border-radius: 8px;
        color: #0d4f62;
        display: flex;
        border: 1px solid #fff;
        background: linear-gradient(0deg, #fff 0%, #effff7 100%);
      }

      &-desc {
        font-size: 12px;
        text-align: center;
        margin-top: 4px;
        color: #0d4f62;
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
          border-radius: 8px;
          padding: 6px 12px;
          border: 1px solid #fff;
          background: linear-gradient(0deg, #fff 0%, #effff7 100%);
        }
      }
    }
  }

  &-types {
    padding: 20px 10px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;

      &-title {
        font-size: 20px;
        color: #fff;
        text-align: left;
        z-index: 2;

        span {
          background-color: #19BC66;
          padding: 2px 6px;
          border-radius: 4px 0;
        }
      }

      &-img {
        margin-top: -10px;
        background-color: #fff;
        padding-top: 20px;
        border-radius: 8px;

        img {
          width: calc(100% - 28px);
        }

        &-desc {
          padding: 10px 14px;
          font-size: 14px;
          color: #657181;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }

  &-bottom {
    text-align: center;
    color: #9a9ca0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.95;
    margin-bottom: 80px;
  }
}

@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
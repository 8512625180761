.assess-section-title {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding: 26px 14px 14px 14px;
}

.assess-section-subtitle {
  color: #333333;
  font-size: 15px;
  text-align: left;
  padding: 0px 14px;
  line-height: 1.8;
}

.assess-section-desc {
  color: #717171;
  font-size: 12px;
  text-align: left;
  line-height: 1.8;
  padding: 5px 14px 25px 14px;
}

.assess-section-line {
  background-color: #EEEEEE;
  margin: 0px 14px;
  height: 1px;
}

.assess-loading-text {
  color: #717171;
  font-size: 14px;
  text-align: center;
  padding: 10px;
}

.assess-section-sep {
  background-color: #F7F7F7;
  height: 10px;
}

.assess-image-list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding: 0px 14px;

  .assess-image-item {
    width: calc((100% - 16px) / 3.0);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  .assess-upload-item {
    width: calc((100% - 16px) / 3.0);
    height: 140px;

    img {
      width: 100%;
    }
  }
}

.assess-image-list-v2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0px 14px 0 4px;

  &-item {
    width: calc((100% - 30px) / 3.0);
    height: 140px;
    margin: 0  0 10px 10px;

    img {
      width: 100%;
    }
  }
}

.assess-test-wrapper {
  font-size: 15px;
  color: #333333;
  margin-bottom: 20px;

  video {
    width: calc(100% - 28px);
  }

  .assess-test-title {
    font-weight: 500;
    text-align: left;
    padding: 14px 14px 17px 14px;
  }

  .assess-test-line {
    margin: 0px 14px;
    border-bottom: 1px #EEEEEE solid;
  }

  .assess-test-table {
    margin: 10px 14px;
    width: calc(100% - 28px);
    border: 1px #ccc solid;
    border-collapse:collapse;

    tr {
      border-bottom: 1px #ccc solid;

      th {
        border-right: 1px #ccc solid;
        padding: 5px 0px;
        background: #e9f7ff;
        color: #3f3f3f;
      }

      td {
        width: 60px;
        border-right: 1px #ccc solid;
        padding: 0px 0px;

        input {
          text-align: center;
          height: 40px;
        }
      }
    }
  }
}

.assess-upload-video-wrapper {
  font-size: 15px;
  color: #333333;
  margin-bottom: 30px;

  .assess-upload-video-title {
    font-weight: 500;
    text-align: left;
    padding: 0px 14px 12px 14px;
  }

  .assess-upload-video-subtitle {
    text-align: left;
    padding: 0px 14px 21px 14px;
  }

  .assess-upload-video-pain {
    padding-bottom: 16px;
  }

  .assess-upload-video-input-wrapper {
    padding: 0px 14px;

    .assess-upload-video-input-demo {
      video {
        width: 100%;
        height: 100%;
      }

      margin-bottom: 12px;
    }

    .assess-upload-video-input-item {
      width: 100%;
    }
  }
}

.assess-submit-wrapper {
  padding: 40px 14px 100px 14px;

  .assess-submit-tips {
    text-align: left;
    font-size: 12px;
    color: #717171;
    line-height: 1.8;
    padding-bottom: 20px;
  }

  .assess-submit-btn {
    text-align: center;
    font-size: 18px;
    color: white;
    background: #01BC70;
    border-radius: 5px;
    padding: 12px 0px;
  }
}

.assess-input-error {
  color: #DC1B33;
  font-size: 12px;
  margin: 0px 14px 10px 14px;
  text-align: left;
}

.assess-result-message {
  font-size: 15px;
}

.assess-result-img {
  width: 60px;
  height: 60px;
}

.assess-result-button {
  margin: 20px 30%;
  border: 1px #EEEEEE solid;
  padding: 10px 10px;
  color: #717171;
  font-size: 15px;
}

.assess-section-tips {
  background: #F7F7F7;
  padding: 12px 14px;
  color: #A1A1AA;
  font-size: 12px;
  text-align: left;

  .assess-service-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .assess-section-tips-icon {
      padding: 0px 4px 0px 0px;
      width: 12px;
      height: 12px;
    }
  }

  .assess-section-rejected-info {
    color: #DC1B33;
    line-height: 1.8;
    margin-top: 10px;
  }
}

.assess-save-draft {
  background-color: rgba(1, 188, 112, 0.7);
  z-index:999;
	position:fixed;
	top:12px;
	right:0;
  padding: 12px 10px 12px 15px;
  border-radius: 25px 0px 0px 25px;
  text-align: center;
  color: white;
  font-size: 14px;
}

@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
.faq-item-container {
  color: #333;
  background-color: #fff;
  font-size: 15px;
  text-align: left;
  padding: 20px 15px 10px 15px;

  .faq-item-title {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    background: #fff url("images/faq_title_bg.png") no-repeat 0 100%;
    background-size: 63px 8px;
  }

  .faq-item-media {
    .faq-item-text {
      line-height: 1.5;
      padding-top: 15px;
    }

    .faq-item-img {
      text-align: center;

      img {
        width: 100%;
        padding-top: 15px;
        text-align: center;
      }
    }
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
.stage-container {
  color: #666;
  background-color: #fff;
  font-size: 14px;
  text-align: left;
  padding: 0 15px 50px 15px;
  border-radius: 10px;

  .stage-title-container {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    padding: 20px 0;

    .mass-icon {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }

    .stage-title {
      font-size: 19px;
      font-weight: 500;
      color: #333;
      flex-grow: 1;
    }
  }

  .stage-video {
    video {
      width: 100%;
    }
  }

  .stage-info {
    background-color: #F5F7F9;
    border-radius: 2px;
    padding: 10px 15px;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin: 15px 0;

    .mass-icon {
      width: 15px;
      height: 15px;
    }

    .mass-title {
      color: black;
      font-size: 15px;
      font-weight: 500;
      flex-grow: 2;
      text-align: left;
      padding: 0 8px;
    }

    .stage-icon {
      width: 50px;
      height: 50px;
    }
  }

  .stage-desc {
    font-size: 14px;
    line-height: 1.6;
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
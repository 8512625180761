.body-content {
    padding-bottom: 120px;
    box-sizing: border-box;
    .title {
      position: relative;
      text-align: center;
      margin: 60px 0 40px;
      color: #4a4a4a;
      font-weight: 500;
      font-size: 20px;
      overflow: hidden;
      &:before{
        position: absolute;
        left: 50%;
        width: 280px;
        height: 1px;
        background-color: #d8d8d8;
        content: '';
        top: 50%;
        -webkit-transform: translate(-360px, -50%);
        transform: translate(-360px, -50%);
      }
      &:after{
        position: absolute;
        right: 50%;
        width: 280px;
        height: 1px;
        background-color: #d8d8d8;
        content: '';
        top: 50%;
        -webkit-transform: translate(360px, -50%);
        transform: translate(360px, -50%);
      }
    }
  }
  .banner-wrap{
    position: relative;
    .banner{
      position: relative;
    }
  }
  .footer-info {
    width: 100%;
    text-align: center;
    margin: 40px 0 0 0;
  }
  .footer-info p {
    font-size: 14px;
    line-height: 20px;
    color: #4C4C4C;
    text-align: center;
  }
  .footer-info p a {
    font-size: 14px;
    line-height: 32px;
    color: #4C4C4C;
    text-align: center;
  }
  
  //@media screen and (max-width: 414px) {
  //  .banner-wrap{
  //    .banner{
  //      .banner-item{
  //        width: 100%;
  //        padding-top: 54%;
  //        background-size: cover;
  //      }
  //    }
  //    .ctrl-btn{
  //      display: none;
  //    }
  //  }
  //  .body-content{
  //    padding: 0 20px 40px;
  //    .title{
  //      margin: 24px 0 16px;
  //      &:before{
  //        width: 100px;
  //        -webkit-transform: translate(-160px, -50%);
  //        transform: translate(-160px, -50%);
  //      }
  //      &:after{
  //        width: 100px;
  //        -webkit-transform: translate(160px, -50%);
  //        transform: translate(160px, -50%);
  //      }
  //    }
  //  }
  //  .intro{
  //    >.p {
  //      font-size: 14px;
  //      line-height: 28px;
  //      margin-bottom: 12px;
  //    }
  //  }
  //}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
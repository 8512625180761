.select-wrapper {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;

  .select-item-selected {
    font-size: 15px;
    border-radius: 4px;
    background-color: #0e80d2;
    color: #ffffff;
    padding: 10px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .select-item-unselected {
    font-size: 15px;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    border: #a8a8a8 1px solid;
    padding: 10px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}


@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
.header-wrapper {
  text-align: center;
  img {
    width: 100%;
  }
  .invite-wrapper {
    position: relative;
    margin-top: -58%;
    text-align: center;
    z-index: 100;
    img {
      width: 295px;
    }
  }
}

.banner-wrapper {
  position: relative;
  margin: 10px 16px 35px 16px;
  .banner{
    position: relative;
  }

  .invite-list {
    background: transparent;
    font-size: 11px ;
    font-weight: 500;
    text-align: center;
    padding: 10px 10px 10px 10px;
    
    .invite-item {
      background: transparent;
      color: rgba(255, 255, 255, 0.5);
      font-size: 11px;
    }
  }
}

.carousel .slide {
  background: transparent;
}

.invite-stat-wrapper {
  background-color: #3A1CD5;
  padding: 0px 15px;
  position: relative;

  .stat-info {
    background-color: #FFA5CD;
    border-radius: 25px 25px 0px 0px;    
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left-info {
      flex-grow: 1;
      text-align: left;
      .reward-title {
        color: #fff;
        font-size: 12px;
      }
      .reward-amount {
        color: #fff;
        font-size: 40px;
        font-weight: 600;
        margin: 10px 0px;
      }
      .reward-total {
        color: #A24F74;
        font-size: 12px;
      }
    }
    .right-info {
      background-color: #fff;
      border-radius: 20px;
      padding: 9px 15px;
      color: #A24F74;
      font-size: 16px;
      line-height: 23px;
    }
  }
}  

.invite-steps-wrapper {
  background-color: #3A1CD5;
  padding: 0px 15px 50px 15px;
  .step-bg {
    background-color: #FFA5CD;
    height: 50px;
  }
  img {
    margin-top: -50px;
    width: 100%;
  }
}

.invite-footer-wrapper {
  background-color: #3A1CD5;
  padding: 0px 30px 120px 30px;
  img {
    width: 100%;
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
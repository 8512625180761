.list-selector {
  border-bottom: 1px #EEEEEE solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0px 14px 0px 14px;
  padding: 10px 0px;
  color: #333333;
  font-size: 15px;
  
  &-title {
    flex-shrink: 0;
    text-align: left;
  }

  &-placeholder {
    flex-grow: 1;
  }

  &-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &-item {
      border-radius: 4px;
      border: 1px solid #D9D9D9;
      height: 32px;
      padding: 0 20px;
      font-size: 17px;
      color: #657181;
      margin-left: 8px;
      display: flex;
      align-items: center;

      &-selected {
        color: #fff;
        background-color: #19BC66;
        border: none;
      }
    }
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;
.bg-container {
  background-color: #363D59;

  .stage-header {
    width: 100%;
    margin-bottom: -50px;
    pointer-events: none;
    -webkit-touch-callout:none; /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;
  }

  .stage-item-wrapper {
    padding: 0 15px 10px 15px;
  }

  .stage-footer {
    margin-top: 66px;
    width: 100%;
    pointer-events: none;
    -webkit-touch-callout:none; /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;
  }
}
@color-text-base: #3A3A3A;@color-text-placeholder: #A7A7A7;@brand-primary: #21DCC6;@brand-warning: #FD6185;@fill-body: #fff;@warning-button-fill: #FD6185;@warning-button-fill-tap: #E9587D;@radius-md: 7px;@tabs-height: 50px;